/* .select-dropdown {
  position: relative;
  min-height: 38px;
  border: 1px solid #d1d3e2;
  border-radius: 1px;
  
  padding: 0;
  min-width: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.select-dropdown .data-toggle {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  min-height: 36px;
  padding: 5px;
  font-size: 12px;
  color: #414141;
  
  font-weight: 400;
}

.select-dropdown .dropdown-menu {
  display: block;
  top: 100%!important;
  left: 0!important;
  transform: translate(0,50px)!important;
  
  visibility: hidden;
  opacity: 0;
  transition: .5s ease all;
  width: 100%;
  padding: 10px;
  border-radius: 0 0 1px 1px;
  box-shadow: rgb(0 0 0 / 10%) 0 2px 5px;
  border: 0;

}

.select-dropdown .dropdown-menu .dropdown-search {
  margin: 0 0 10px;
}

.select-dropdown .dropdown-menu .dropdown-list {
  max-height: 180px;

  overflow: auto;
}

.select-dropdown .custom-radio .custom-radio-label {
  position: relative;
  margin: 0;
  line-height: 1.2;
  font-size: 13px;
  font-family: 'Poppins',sans-serif;
  font-weight: 400;
  letter-spacing: -.3px;
}

.select-dropdown .custom-radio .custom-radio-label::before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 1px solid #8d99ae;
  top: 50%;
  left: -24px;
  transform: translateY(-50%);
  border-radius: 50%;
}

.custom-radio .custom-radio-label:after {
  content: "\f111";
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  width: 16px;
  height: 16px;
  top: 50%;
  left: -24px;
  transform: translateY(-50%);
  font-weight: 600;
  font-size: 8px;
  display: none;
  color: #fff;
  text-align: center;
  margin-top: 1px;
  line-height: 16px;
}


.select-dropdown .data-toggle span {
  display: flex;
  background-color: var(--primaryColor);
  color: #fff;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 300;
  margin: 2px;

}

.select-dropdown .dropdown-menu .dropdown-list .option-group-header {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 5px;
  padding: 0 0 5px;
  
  border-bottom: 1px solid rgba(0,0,0,.1);

}

.select-dropdown .dropdown-menu .dropdown-list .item+.option-group-header {
  margin: 10px 0 5px;
} */



.select-dropdown{position:relative;min-height:38px;border:1px solid #d1d3e2;padding:0;min-width:100px;display:flex;justify-content:flex-start;align-items:center;border-radius:6px}
.select-dropdown .data-toggle{display:flex;align-items:center;flex-wrap:wrap;width:100%;height:100%;min-height:36px;padding:5px;font-size:12px;color:#414141;font-weight:400}
.select-dropdown .dropdown-menu{display:block;top:100%!important;left:0!important;transform:translate(0,50px)!important;visibility:hidden;opacity:0;transition:.5s ease all;width:100%;padding:10px;border-radius:0 0 1px 1px;box-shadow:rgba(0,0,0,0.1) 0 2px 5px;border:0}
.select-dropdown .dropdown-menu .dropdown-search{margin:0 0 10px}
.select-dropdown .dropdown-menu .dropdown-list{max-height:180px;overflow:auto}
.select-dropdown .custom-radio .custom-radio-label{position:relative;margin:0;line-height:1.2;font-size:13px;font-family:'Poppins',sans-serif;font-weight:400;letter-spacing:-.3px}
.select-dropdown .custom-radio .custom-radio-label::before{content:'';position:absolute;width:16px;height:16px;background:#fff;border:1px solid #8d99ae;top:50%;left:-24px;transform:translateY(-50%);border-radius:50%}
.custom-radio .custom-radio-label:after{content:"\f111";position:absolute;font-family:"Font Awesome 5 Pro";width:16px;height:16px;top:50%;left:-24px;transform:translateY(-50%);font-weight:600;font-size:8px;display:none;color:#fff;text-align:center;margin-top:1px;line-height:16px}
.select-dropdown .data-toggle span{display:flex;background-color:var(--primaryColor);color:#fff;padding:2px 5px;border-radius:4px;font-size:10px;font-weight:300;margin:2px}
.select-dropdown .dropdown-menu .dropdown-list .option-group-header{font-size:14px;font-weight:600;margin:0 0 5px;padding:0 0 5px;border-bottom:1px solid rgba(0,0,0,.1)}
.select-dropdown .dropdown-menu .dropdown-list .item+.option-group-header{margin:10px 0 5px}