.schedule_list .schedule-row {
  position: relative;
}

.schedule_list .schedule-row .schedule-title {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  background-color: rgba(48,122,213,.2);
  padding: 5px 10px;
  color: var(--primaryColor);
  margin: 0 0 5px;
}

.schedule_list .schedule-row .schedule-half {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  border-bottom: 1px dashed rgba(0,0,0,.1);
}

.schedule_list+.schedule_list {
  margin-top: 10px;
}

/*
.time-slots{position: relative;display: flex;flex-wrap: wrap;margin: 0 -5px;}
.time-slots .slots{display: flex;justify-content: center;flex-direction: column;align-items: center;position: relative;overflow: hidden;width: 50%;max-width: calc(50% - 10px);margin: 5px;}
.time-slots .slots .slots-input{position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: 1;opacity: 0;cursor: pointer;}
.time-slots .slots .slots-range{display: flex;align-items: center;background-color: rgba(0,0,0,0.02);padding: 10px;border-radius: 4px;border: 1px solid rgba(0,0,0,0.05);overflow: hidden;width: 100%;justify-content: center;transition: all 0.3s ease;}
.time-slots .slots .slots-range .range{display: flex;flex-direction: column;line-height: 1;align-items: center;font-size: 14px;font-weight: 500;position: relative;}
.time-slots .slots .slots-range .range span{display: block;line-height: 1;font-size: 10px;font-weight: 500;font-family: 'Roboto', sans-serif;margin: 2px 0 0;color: var(--secondaryColor);opacity: 0.75;transition: all 0.3s ease;}
.time-slots .slots .slots-range .range+.range{margin-left: 20px;}
.time-slots .slots .slots-range .range+.range:before{content:'';position: absolute;width: 8px;height: 1px;background-color: var(--thirdColor);top: 50%;left: -14px;margin-top: -1px;transition: all 0.3s ease;}
.time-slots .slots .slots-input:checked ~ .slots-range{background-color:var(--primaryColor); color: #fff;}
.time-slots .slots .slots-input:checked ~ .slots-range .range span{color: #fff;}
.time-slots .slots .slots-input:checked ~ .slots-range .range+.range:before{background-color: #fff;opacity: 0.5;}
*/