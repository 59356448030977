.timezone {
  margin: 0;
  display: flex;
  width: 100%;
  max-width: calc(100% - 38px);
}

.overflow-unset {
  overflow: unset!important;
}

.timezone .dropdown-toggle .timezone-name {
  display: block;
  line-height: 15px;
  color: #999;
  font-size: 13px;
  font-weight: 400;
}

.timezone .dropdown-toggle {
  display: flex;
  margin: auto 0;
  padding: 6px 10px!important;
  width: 100%;
  border: 0;
  min-height: 38px;
  align-items: center;
  overflow: hidden;
}

.timezone .dropdown-toggle .timezone-name {
  display: block;
  line-height: 15px;
  color: #999;
  font-size: 13px;
  font-weight: 400;
}

.timezone .dropdown-menu {
  margin: 0;
  border: 0;
  border-radius: 0;
  box-shadow: rgb(0 0 0 / 20%) 0 2px 10px;
  left: auto!important;
  right: 0;
  transform: translate(0,50px)!important;
  width: 100%;
}

.timezone .dropdown-menu .input-search {
  padding: 0 5px;
  margin: 0 0 5px;
}

.timezone .dropdown-menu .input-search .form-control {
  height: 30px;
  min-height: 30px;
  border: 1px solid #d1d3e2!important;
  max-width: 100%;
}

.timezone .dropdown-menu .dropdown-menu-wrap {
  max-height: 200px;
  overflow: auto;
}

.timezone .dropdown-menu .dropdown-item {
  display: flex;
  padding: 5px 10px;
  color: var(--secondaryColor);
}

.timezone .dropdown-menu .dropdown-item .timezone-name {
  display: block;
  line-height: 15px;
  font-size: 13px;
}

.timezone .dropdown-menu.show {
  transform: translate(0,0)!important;
}

.dropdown .dropdown-menu.show {
  display: block;
  transform: translate(-50%,0)!important;
  visibility: visible !important;
  opacity: 1;
  transition: .5s ease all;
}

.timezone .dropdown-menu .input-search .form-control {
  height: 30px;
  min-height: 30px;
  border: 1px solid #d1d3e2!important;
  max-width: 100%;
}