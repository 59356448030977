/*
Version: 1.0
Author: Symlink Technology
Author URI: https://www.symlinktech.com/.
*/
@media screen and (max-width: 1400px) {
	header .header-bottom-content .navigation .navigation-wrap .navigation-menu > li .menu-item{padding:8px 10px}
}
@media screen and (max-width: 1024px) {
	header .header-bottom-content .navigation .navigation-wrap .btn-menu{display:flex}
	header .header-bottom-content .navigation .navigation-wrap .navigation-menu{display:block;position:fixed;top:0;left:0;width:300px;background:var(--secondaryColor);height:100%;padding:30px 20px;overflow:auto;z-index:99;transform:translateX(-110%);transition:all .3s ease}
	header .header-bottom-content .navigation .navigation-wrap .navigation-menu.active{transform:translateX(0%)}
	header .header-bottom-content .navigation .navigation-wrap .navigation-menu > li .menu-item{color:#fff;padding:12px 0}
	header .menu-backdrop{display:block;position:fixed;top:0;left:0;width:100%;height:100%;background:var(--secondaryColor);opacity:0;z-index:9;visibility:hidden;transition:all .3s ease}
	header .menu-backdrop.active{opacity:.5;visibility:visible}
	header .header-bottom-content .navigation .navigation-wrap .navigation-menu > li .menu-item:hover, header .header-bottom-content .navigation .navigation-wrap .navigation-menu > li.active .menu-item{color: var(--color02);}
  
  header .header-bottom-content .navigation .navigation-wrap .navigation-menu .intervention-dropdown{flex-wrap:wrap;}
  header .header-bottom-content .navigation .navigation-wrap .navigation-menu .intervention-dropdown .dropdown-toggle:after{color: var(--color02);}
  header .header-bottom-content .navigation .navigation-wrap .navigation-menu .intervention-dropdown .dropdown-menu{position: relative !important;width: 100%;min-width: 0;max-width: 100%;transform: none !important;display: none;right: auto !important;left: auto !important;z-index: 999;opacity: 1;visibility: visible;background-color: rgba(0,0,0,0.2);box-shadow: none;border-radius: 4px;}
  header .header-bottom-content .navigation .navigation-wrap .navigation-menu .intervention-dropdown .dropdown-menu.show{display: block;}
  header .header-bottom-content .navigation .navigation-wrap .navigation-menu .intervention-dropdown .dropdown-item{color: var(--white);font-weight: 300;font-size: 13px;}
  header .header-bottom-content .navigation .navigation-wrap .navigation-menu .intervention-dropdown .dropdown-item:hover{background-color:var(--color02); }
  
	.consultant-wrap .item .item-name{font-size:16px}
	.consultant-wrap .item .item-designation{font-size:12px}
	.sec-what .sec-what-image{width:40%}
	/*.steps-wrap .item{width:30%;max-width:calc(30% - 20px)}*/
	.sec-testimonial .testimonial-slider .owl-nav button.owl-prev{left: 0;}
	.sec-testimonial .testimonial-slider .owl-nav button.owl-next{right: 0;}
	.sec-dashboard .list-card{flex-wrap:wrap}
	.sec-dashboard .list-card .list-title{max-width:calc(100% - 110px)}
	.sec-dashboard .list-card .date-time-wrapper{margin-right:10px}
	.sec-dashboard .list-card .action-wrapper{max-width:100%;flex-direction:row;width:100%;justify-content:space-between;margin:10px 0 0}
	.sec-dashboard .list-card .action-wrapper .status{margin:auto 0;width:100px}
	.recent-blogs .recent-blogs-list .blog-item{min-height: 100px;}
	.recent-blogs .recent-blogs-list .blog-item .item-image{ width: 120px;}
	.recent-blogs .recent-blogs-list .blog-item .item-content{max-width: calc(100% - 120px);}
	.recent-blogs .recent-blogs-list .blog-item .item-text{display:none;}
}

@media screen and (max-width: 990px) {
	.about-wrap .welcome{width: 100%;max-width: calc(100% - 30px);}
	.about-wrap .counter{width: 100%;max-width: calc(100% - 30px); margin-top: 40px;}
	.about-wrap .counter .item{max-width: calc(25% - 20px);}
	.about-wrap .counter .item .item-title{font-size:13px;line-height:1.2;text-align:center}
	
	.service-wrap .item{width:50%;max-width:calc(50% - 20px)}
	.steps-wrap .item .item-wrap{width: 100%;max-width: 320px;}
	
	.consultant-wrap .item .item-stats{width:100%;padding:20px}
	.consultant-wrap .item .item-info{width:100%;max-width:100%;padding-top:0;text-align:center}
	.category-wrap .item{max-width: calc(50% - 20px);}
	.category-wrap .item-title{font-size:16px}
	.calltoaction-wrap .calltoaction-content{max-width:calc(100% - 200px)}
	.calltoaction-wrap .calltoaction-action{width:200px}
	.consultant-wrap .item .item-intervention .intervention-data{color:var(--thirdColor);font-weight:400;line-height:1.2}
	.consultant-wrap .item .item-intervention{flex-direction:column;justify-content:center;align-items:center}
	.consultant-wrap .item .item-nationality{justify-content:center}
	footer .footer-content .text{margin:0 0 50px}
	.consultant-filter .filter-content-wrap .filter-row .filter-options .custom-checkbox{width:33.33%;max-width:calc(33.33% - 20px)}
	.sec-dashboard .card-nav .nav.nav-pills.nav-fill{flex-wrap:nowrap;overflow:auto}
	.sec-dashboard .card-nav .nav.nav-pills.nav-fill .nav-link{white-space:nowrap}
	.rating-widget .rating-list .rating-item .star{font-size:20px}
	.consultant-details .consultant-side-panel{width:320px}
	.consultant-details .consultant-service .consultant-service-cost .item{align-items:center}
	.consultant-details .consultant-service .consultant-service-cost .item .item-name{max-width:calc(100% - 210px)}
	.recent-blogs{position:relative; top: auto; margin: 40px 0 0;}
	.recent-blogs .recent-blogs-list .blog-item .item-image{ width: 160px;}
	.recent-blogs .recent-blogs-list .blog-item .item-content{max-width: calc(100% - 160px);}
	.recent-blogs .recent-blogs-list .blog-item .item-text{display:block;}
	.sec-similar-blog .blog-wrap .blog-item{max-width: calc(50% - 20px);}
	.sec-comunication.active .comunication-body{width: 100%;}

	.stripe-card-list .card-item{width: 100%;max-width: calc(100% - 10px);}
}

@media screen and (max-width: 990px) and (orientation: landscape){
	header .header-bottom-content{padding:5px 0}
	header .header-bottom-content .navigation .navigation-wrap .authentication-menu > li{display:flex}
	header .header-bottom-content .navigation .navigation-wrap .authentication-menu > li.signin{display:block}
	header .header-bottom-content .navigation .navigation-wrap .authentication-menu > li.signin .menu-item{font-size:13px}
	header .header-bottom-content .navigation .navigation-wrap .authentication-menu > li.user-account{display:block}
	header .header-bottom-content .navigation .navigation-wrap .authentication-menu > li.user-account .menu-item{font-size:13px;padding:0;border:0;line-height:1;text-align:center}
	header .header-bottom-content .navigation .navigation-wrap .authentication-menu > li.user-account .menu-item span{display:none}
	.sec-banner{min-height: 400px;height:auto;padding:40px 0}
	.sec-banner .banner-content{width:calc(100% - 30px);margin:0 auto}
	.sec-banner .banner-content .banner-title{font-size:36px}
	.sec-banner .banner-content .banner-subtitle{font-size:16px;line-height:1.2}
	.sec-banner .banner-search .search-wrap{width:calc(100% - 20px);margin:0 auto}
	.section-title .title{font-size:24px;line-height:1.2;letter-spacing:-.5px}
	.steps-wrap .item{padding:20px}
	.sec-what .sec-what-image{width:35%}
	.calltoaction-wrap .calltoaction-content{width:100%;max-width:100%;margin:0 0 30px}
	.calltoaction-wrap .calltoaction-content .title{font-size:24px}
	.calltoaction-wrap .calltoaction-content .text{font-size:16px}
	.calltoaction-wrap .calltoaction-action{width:100%;max-width:100%}
	.calltoaction-wrap .calltoaction-action .btn-action{margin:0}
	.consultant-filter .filter-content-wrap .filter-row .filter-options .custom-checkbox{width:50%;max-width:calc(50% - 20px)}
	.page-banner .banner-content .banner-title{font-size:32px}
	footer .footer-content .text > p{margin:0 0 10px}
}

@media screen and (max-width: 767px) {
	header .header-bottom-content{padding:5px 0}
	header .header-bottom-content .navigation .navigation-wrap .authentication-menu > li{display:none}
	header .header-bottom-content .navigation .navigation-wrap .authentication-menu > li.signin{display:block}
	header .header-bottom-content .navigation .navigation-wrap .authentication-menu > li.signin .menu-item{font-size:13px}
	header .header-bottom-content .navigation .navigation-wrap .authentication-menu > li.user-account{display:block}
	header .header-bottom-content .navigation .navigation-wrap .authentication-menu > li.user-account .menu-item{font-size:13px;padding:0;border:0;line-height:1;text-align:center}
	header .header-bottom-content .navigation .navigation-wrap .authentication-menu > li.user-account .menu-item span{display:none}
	header .header-bottom-content .navigation .navigation-wrap .authentication-menu > li.notification{display:flex}
	.about-wrap .counter .item{max-width: calc(50% - 20px);}
	.consultant-details .consultant-side-panel{position:relative;top:auto}
	.sec-consultant-details{padding:20px 0}
	.sec-banner{min-height:400px}
	.sec-banner .banner-content{width:calc(100% - 30px);margin:0 auto}
	.sec-banner .banner-content .banner-title{font-size:36px}
	.sec-banner .banner-content .banner-subtitle{font-size:16px;line-height:1.2}
	.sec-banner .banner-search .search-wrap{width:calc(100% - 20px);margin:0 auto}
	.section-title .title{font-size:24px;line-height:1.2;letter-spacing:-.5px}
	.sec-what .sec-what-image{width:35%}
	.calltoaction-wrap .calltoaction-content{width:100%;max-width:100%;margin:0 0 30px}
	.calltoaction-wrap .calltoaction-content .title{font-size:24px}
	.calltoaction-wrap .calltoaction-content .text{font-size:16px}
	.calltoaction-wrap .calltoaction-action{width:100%;max-width:100%}
	.calltoaction-wrap .calltoaction-action .btn-action{margin:0}
	.consultant-filter .filter-content-wrap .filter-row .filter-options .custom-checkbox{width:50%;max-width:calc(50% - 20px)}
	.page-banner .banner-content .banner-title{font-size:32px}
	.consultant-reviews .overall-rating-list{flex-direction: column;}
	.consultant-reviews .overall-rating .item{border:0;}
	.consultant-reviews .overall-rating .item+.item{border:0;border-top:1px solid rgba(0,0,0,0.1);} 
}
@media screen and (max-width: 480px) {
	header .header-top-content{justify-content:space-between;flex-wrap: wrap;}
	header .header-top-content .contact.chat-join{display: flex;justify-content: center;margin: 10px 0px 10px;order: 2;}
	header .header-top-content .contact{display:none}
	header .header-top-content .language{margin-left:0;border-left:0;padding:10px 0}
	header .header-bottom-content .brand{max-width:160px}
	header .header-bottom-content .navigation{max-width:calc(100% - 170px);margin-left:10px}
	header .header-bottom-content .navigation .navigation-wrap .btn-menu{margin-left:10px}
	header .header-bottom-content .navigation .navigation-wrap .authentication-menu{margin-left:0}
	header .header-bottom-content .navigation .navigation-wrap .authentication-menu>li.user-account .user-item span{display:none;}
	.sec-banner{min-height:calc(100vh - 105px)}
	.about-wrap .welcome{width:100%;max-width:calc(100% - 30px)}
	.about-wrap .counter{width:100%;max-width:calc(100% - 30px);margin:50px auto 0}
	.about-wrap .counter .item{min-height:140px}
	.consultant-details .consultant-details-panel{margin:20px 0 0}
	.service-wrap .item{width:100%;max-width:calc(100% - 20px)}
	.steps-wrap .item{padding:20px;max-width:100%;}
	.steps-wrap .item+.item{margin-top: 0;}
	.steps-wrap .item .item-wrap:before,.steps-wrap .item:nth-child(even) .item-wrap:before{width: 0;border-radius: 0;border: 0;border-right: 10px solid rgba(255,255,255,0.5);top: 100%;right: auto;left: 50%;transform: translateX(-50%);}
	.consultant-wrap .item{width:100%;max-width:calc(100% - 30px)}
	.category-wrap .item{width:100%;max-width:calc(100% - 20px)}
	.category-wrap .item-title{font-size:14px}
	.category-wrap .item-stats .item-count{font-size:32px}
	.category-wrap .item-stats .item-stats-title{font-size:12px}
	.sec-what .sec-what-image{display:none}
	.widget-wrap .item{width:100%;max-width:calc(100% - 20px)}
	.sec-what .what-wrap{text-align:center}
	.sec-what .what-wrap .title{font-size:32px;line-height:1}
	.sec-what .what-wrap .sub-title{font-size:24px;margin:20px 0 0}
	.sec-testimonial .testimonial-slider .item{width: calc(100% - 60px);padding: 20px;}
	.sec-testimonial .testimonial-slider .item .item-title{font-size: 18px;}
	.sec-testimonial .testimonial-slider .item .item-subtitle{font-size: 14px;}
	.sec-testimonial .testimonial-slider .item .item-description{font-size: 13px;line-height: 1.4;}
	.sec-testimonial .testimonial-slider .item .item-info{margin: 10px 0 0;}
	.sec-testimonial .testimonial-slider .owl-nav button.owl-next, .sec-testimonial .testimonial-slider .owl-nav button.owl-prev{font-size: 30px;}
	.prefooter-last{padding-bottom: 120px !important;}
	footer .footer-content{margin:0 0 40px}
	footer .footer-content .text{margin:0}
	footer .footer-bottom > .container{flex-wrap:wrap;justify-content:center}
	.consultant-filter .filter-content-wrap .filter-row .filter-options .custom-checkbox{width:100%;max-width:calc(100% - 20px)}
	.consultant-filter .filter-content-wrap .btn-clear-filter{top:15px;right:15px}
	.sec-dashboard .container-bg .header-wrap .header-profile{display:none}
	.sec-dashboard .menulink-button{margin-left:auto}
	.sec-dashboard .col-md-6.col-lg-6{display:flex}
	.sec-dashboard .container-bg .header-wrap{flex-direction:row-reverse;flex-wrap:wrap}
	.sec-dashboard .container-bg .side-bar.active{transform:translateX(0%)}
	.sec-dashboard .container-bg .side-bar{position:fixed;width:300px;z-index:100;top:105px;left:0;height:calc(100% - 105px);background-color:#fff;padding:20px 0;margin:0;transform:translateX(-110%);transition:all .3s ease}
	.sec-dashboard .container-bg .sidebar-overlay{display:block;position:fixed;top:0;left:0;width:100%;height:100%;background:var(--secondaryColor);opacity:0;z-index:99;visibility:hidden;transition:all .3s ease}
	.sec-dashboard .container-bg .sidebar-overlay.active{opacity:.5;visibility:visible}
	.sec-dashboard{padding-top:0}
	.consultant-details .consultant-side-panel,.consultant-details .consultant-details-panel{width:100%;max-width:100%;margin:0}
	.how-it-works .item{flex-wrap:wrap;margin: 0 !important;}
	.how-it-works .item .item-img{width: 100%; max-width: 100%; margin: 0 0 20px !important;}
	.how-it-works .item .item-content{width: 100%; max-width: 100%; margin: 0 0 20px;text-align: left !important;}
	.recent-blogs .recent-blogs-list .blog-item .item-image{ width: 140px;}
	.recent-blogs .recent-blogs-list .blog-item .item-content{max-width: calc(100% - 140px);}
	.recent-blogs .recent-blogs-list .blog-item .item-text{display:block;}
	.sec-similar-blog .blog-wrap .blog-item{max-width: calc(100% - 20px);}
	.sec-dashboard .container-bg .card-container{margin-left: -15px;}
	.emails-list .email .e-content{max-width: calc(100% - 80px);}
	.emails-list .email .e-option{width: 100%;}
	.manageAppointment .cont{flex-direction: column;height: auto;min-height: 400px;border: 0;margin: 20px 0;}
	.manageAppointment .cont .videos{width: 100%;min-height: 300px;display: flex;border-radius:20px 20px 0 0;overflow: hidden;}
	.manageAppointment .cont .videos div:first-child{width: 100%; height: auto;}
	.manageAppointment .cont .confirmBox{width: 100% !important;padding: 20px;background-color: #fff;border-radius: 0 0 20px 20px; box-shadow: rgba(0,0,0,0.1) 0 2px 20px;}
	.sec-comunication .comunication-header{flex-wrap: wrap;padding: 0;border-bottom: 1px solid rgba(0,0,0,0.1);}
	.sec-comunication .comunication-header .header-brand{width: 100%;padding: 10px;height: 60px; border-bottom: 1px solid rgba(0,0,0,0.1);}
	.sec-comunication .comunication-header .header-info{border:0;margin: 0;height: auto;min-height:58px;padding: 10px 0;padding-left: 10px;max-width: calc(100% - 150px);}
	.sec-comunication .comunication-header .header-title{font-size: 16px;}
	.sec-comunication .comunication-header .header-text{font-size: 12px;}
	.sec-comunication .comunication-header .header-options .call-timer{padding: 0 5px;font-size: 16px;}
	.sec-comunication .comunication-header .header-options .header-actions .btn-action{padding: 0 5px; border: 0;}
	.sec-comunication .comunication-header .header-options .header-actions .btn-action span{display: none;}
	.sec-comunication .comunication-body > div:first-child{height: calc(100vh - 119px);}
	.sec-comunication .communication-volume-container{max-width: 100%;}
	.sec-comunication .comunication-panel{height: calc(100% - 119px);top: 119px;}
  .message-container .message-header .message-user .title{font-size: 16px;}
  .message-container .message-header .message-header-action .call-timer{padding: 0 5px;font-size: 16px;}
  .message-container .message-header .message-header-action .btn-action{padding: 0 5px; border: 0;}
  .message-container .message-header .message-header-action .btn-action span{display: none;}
  .sec-comunication .comunication-body > div:first-child > div:first-child{width: 100px; height: 120px;}
}
@media screen and (max-width: 359px) {
	header .header-bottom-content .brand{max-width: 140px;}
	header .header-bottom-content .navigation{max-width: calc(100% - 150px);margin-left: 10px;}
	header .header-bottom-content .navigation .navigation-wrap .btn-menu{margin-left: 0px;}
}