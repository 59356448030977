.message-details {
  position: relative;
}

.message-details .message-body {
  position: relative;
  background-color: rgba(48,122,213,.05);
  padding: 20px;
  height: 400px;
  overflow: auto;
  border: 1px solid rgba(0,0,0,.05);
  border-radius: 10px;
}

.message-details .message-body .message {
  position: relative;
  display: flex;
}

.message-details .message-body .message.message-right {
  flex-direction: row-reverse;
}

.message-details .message-body .message .message-image {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
}

.message-details .message-body .message-left .message-image {
  margin-right: 20px;
}

.message-details .message-body .message-right .message-image {
  margin-left: 20px;
}

.message-details .message-body .message .message-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.message-details .message-body .message .message-content {
  position: relative;
  width: calc(100% - 60px);
  max-width: 480px;
}

.message-details .message-body .message.message-left .message-content {
  text-align: left;
}

.message-details .message-body .message.message-right .message-content {
  text-align: right;
}

.message-details .message-body .message .message-content .message-user {
  font-size: 11px;
  font-weight: 400;
  letter-spacing: -.3px;
  line-height: 1.2;
  margin: 0 0 4px;
  color: var(--thirdColor);
}

.message-details .message-body .message .message-content .message-text-list .message-text-wrap {
  position: relative;
  display: flex;
}

.message-details .message-body .message.message-right .message-text-list .message-text-wrap {
  justify-content: flex-end;
}

.message-details .message-body .message .message-content .message-text-list .message-text-wrap .message-text {
  font-size: 13px;
  background-color: #fff;
  padding: 10px;
  font-weight: 400;
  letter-spacing: -.3px;
  box-shadow: rgb(0 0 0 / 5%) 0 1px 3px;
  border-radius: 0 10px 10px 0;
}

.message-details .message-body .message.message-right .message-text-list .message-text-wrap .message-text {
  border-radius: 10px 0 0 10px;
}

.message-details .message-body .message .message-content .message-text-list .message-text-wrap:last-child .message-text {
  border-radius: 0 10px 10px;
}

.message-details .message-body .message.message-right .message-text-list .message-text-wrap:last-child .message-text {
  border-radius: 10px 0 10px 10px;
}

.message-details .message-body .message .message-content .message-date {
  font-size: 10px;
  font-weight: 500;
  color: var(--thirdColor);
  margin: 4px 0 0;
}

.message-details .message-body .message+.message {
  margin: 20px 0 0;
}

.message-details .message-footer {
  position: relative;
  margin: 20px 0 0;
}

.message-details .message-footer .message-form {
  display: flex;
  align-items: center;
}

.message-details .message-footer .message-form .input-wrap {
  width: 100%;
  max-width: calc(100% - 80px);
  margin-right: 20px;
}

.message-details .message-footer .message-form .input-wrap .form-control {
  background-color: rgba(48,122,213,.05);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -.3px;
  border: 1px solid rgba(0,0,0,.05);
  border-radius: 10px;
  line-height: 1.4;
  height: 60px;
  min-height: 0;
  padding: 10px 15px;
}

.message-details .message-footer .message-form .input-action {
  width: 60px;
  height: 60px;
}

.message-details .message-footer .message-form .input-action .btn-action {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #fff;
  background-color: var(--primaryColor);
  border-radius: 10px;
}