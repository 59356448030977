/* .consultant-reviews {
  background: #fff;
  overflow: hidden;
  margin: 0 0 20px;
}

.consultant-reviews .title {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
  margin: 0 0 10px;
  color: #212121;
}

.consultant-reviews .overall-rating {
  display: flex;
  justify-content: center;
  margin: 0 -10px 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.consultant-reviews .overall-rating .item {

  width: 33.33%;
  max-width: calc(33.33% - 20px);
  margin: 5px 10px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.1);
  padding: 5px;
  border-radius: 10px;
  display: flex;

  flex-direction: column;
  align-items: center;

  min-height: 100px;
  justify-content: center;
}

.consultant-reviews .overall-rating .item .item-name {

  font-size: 13px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  line-height: 1;
  margin: 0 0 10px;
}


.rating {
  width: 90px;
  position: relative;
  margin: auto 0;
}

.rating .back {
  font-size: 16px;
  letter-spacing: -5px;
  width: 90px;
  white-space: nowrap;
  overflow: hidden;
  color: #e8eaf6;
}

.rating .back i {
  width: 18px;
}

.rating .front i {
  width: 18px;
}

.rating .front {
  font-size: 16px;
  letter-spacing: -5px;
  width: 90px;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  color: #FF9800;
  z-index: 1;
}

.consultant-reviews .overall-rating .item .item-rating .rating, .consultant-reviews .overall-rating .item .item-rating .rating .back {
  font-size: 24px;
  width: 130px;
}

.consultant-reviews .overall-rating .item .item-rating .rating .back i {
  width: 26px;
}

.consultant-reviews .overall-rating .item .item-rating .rating .front {
  font-size: 24px;
  width: 130px;
}

.consultant-reviews .overall-rating .item .item-rating .rating .front i {
  width: 26px;
}

.consultant-reviews .review-list {
  position: relative;
}

.consultant-reviews .review-list .item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0;
}

.consultant-reviews .review-list .item-image {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 0;
}

.consultant-reviews .review-list .item-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.consultant-reviews .review-list .item-content {
  width: 100%;
  max-width: calc(100% - 120px);
  margin: 0 0 0 20px;
}

.consultant-reviews .review-list .item-content {
  width: 100%;
  max-width: calc(100% - 120px);

  margin: 0 0 0 20px;
}

.consultant-reviews .review-list .item-content .item-name {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0 0 5px;

  color: var(--secondaryColor);
}

.consultant-reviews .review-list .item-content .item-rating {
  margin: 0 0 10px;
}

.consultant-reviews .review-list .item-content .item-comments {
  font-family: "Open Sans",sans-serif;
  font-weight: 300 !important;
  color: #212121;
  line-height: 1.4;
}

.consultant-reviews .review-list .item-content .item-date  {
  font-weight: 300;
  line-height: 18px;
  font-size: 14px;
  font-size: 12px;
} */

.consultant-reviews{background:#fff;overflow:hidden;margin:0 0 20px}
.consultant-reviews .title{display: flex;justify-content: space-between;padding-bottom: 15px;border-bottom: 1px solid rgba(0,0,0,0.1);font-size: 16px;font-weight: 500;}
.consultant-reviews .overall-rating{margin: 0;border-bottom: 1px solid rgba(0,0,0,.1);}
.consultant-reviews .overall-rating-list {display: flex;justify-content: center;margin: 0;align-items: center;}
.consultant-reviews .overall-rating-image{width: 100%;max-width: calc(100% - 280px);}
.consultant-reviews .overall-rating .item{width: 100%;max-width: 100%;margin: 0;background-color: transparent;border: 0;padding:15px;border-radius:0;display: flex;flex-direction: column;align-items: center;min-height: 160px;justify-content: center;}
.consultant-reviews .overall-rating .item+.item{border-left: 1px solid rgba(0,0,0,0.1);}
.consultant-reviews .overall-rating .item .item-name{font-size: 14px;text-transform: capitalize;text-align: center;font-weight: 600;line-height: 1;margin: 0 0 10px;max-width: 140px;color: var(--secondaryColor);}
.rating .back{font-size:16px;letter-spacing:-5px;width:90px;white-space:nowrap;overflow:hidden;color:#ccc}
.rating .back i{width:18px}
.rating .front i{width:18px}
.rating .front{font-size:16px;letter-spacing:-5px;width:90px;white-space:nowrap;overflow:hidden;position:absolute;top:0;left:0;color:#FF9800;z-index:1}
.consultant-reviews .overall-rating .item .item-rating .rating,.consultant-reviews .overall-rating .item .item-rating .rating .back{font-size:24px;width:130px}
.consultant-reviews .overall-rating .item .item-rating .rating .back i{width:26px}
.consultant-reviews .overall-rating .item .item-rating .rating .front{font-size:24px;width:130px}
.consultant-reviews .overall-rating .item .item-rating .rating .front i{width:26px}


.consultant-reviews .review-list{position: relative;}
.consultant-reviews .review-list .item{position: relative;display: flex;flex-wrap: wrap;padding: 15px 0;}
.consultant-reviews .review-list .item+.item{border-top:1px solid rgba(0,0,0,0.05);}
.consultant-reviews .review-list .item-image{position: relative;width: 80px;height: 80px;overflow: hidden;border-radius: 50%;background-color: #fff;border: 1px solid #ccc; z-index: 0;}
.consultant-reviews .review-list .item-image:before{content: '';position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-color: transparent;z-index: 1;border: 5px solid #eee;border-radius: 50%;}
.consultant-reviews .review-list .item-image:after{content: '';position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-color: transparent;z-index: 2;border: 4px solid #fff;border-radius: 50%;}
.consultant-reviews .review-list .item-image img{width:100%; height:100%; object-fit:cover;}
.consultant-reviews .review-list .item-content{width: 100%;max-width: calc(100% - 100px);margin: 0 0 0 20px;}
.consultant-reviews .review-list .item-header{display: flex; align-items: center; justify-content: space-between;}
.consultant-reviews .review-list .item-content .item-date{ font-weight: 400;line-height: 18px;font-size: 12px;color: var(--thirdColor);}
.consultant-reviews .review-list .item-content .item-name{font-size: 15px;font-weight: 600;line-height: 1.5;margin: 0 auto 0 0;color: var(--secondaryColor);}
.consultant-reviews .review-list .item-content .item-rating{margin:0 0 0 auto;}
.consultant-reviews .review-list .item-content .item-comments{font-family: 'Roboto', sans-serif;font-weight: 300;color: var(--secondaryColor);line-height: 1.4;margin: 10px 0 0;}
.consultant-reviews .review-list .item-content .detailed-review{display: flex;flex-wrap: wrap;margin: 0 -10px;}
.consultant-reviews .review-list .item-content .detailed-review .review-summary{width: 50%;max-width: calc(50% - 20px);margin: 0 10px;}
.consultant-reviews .review-list .item-content .detailed-review .review-summary .summary-item{font-size: 14px;font-weight: 400;padding: 5px 0;line-height: 1;color: var(--secondaryColor);}
.consultant-reviews .review-list .item-content .detailed-review .review-rating{width: 50%;max-width: calc(50% - 20px);margin: 0 10px;}
.consultant-reviews .review-list .item-content .detailed-review .review-rating .item-rating{display: flex;justify-content: flex-end;align-items: center;padding: 2px 0;} 
.consultant-reviews .review-list .item-content .detailed-review .review-rating .item-rating .rating-title{font-size: 14px;font-weight: 500;color: var(--secondaryColor);line-height: 1;margin-right: 20px;}
.consultant-reviews .review-list .item-content .detailed-review .review-rating .item-rating .rating{width: 100px;}

























